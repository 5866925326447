// Hack for IE11 not having NodeList.prototype.forEach()
if (!NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

const tenableEval = {
  tioApps: ['vm', 'was', 'lumin'],
  dev: false,
  bannedDomains: [],
  strings: {
    en: {
      firstName: 'First Name',
      lastName: 'Last Name',
      businessEmail: 'Business Email',
      email: 'Email',
      phone: 'Phone',
      code: 'Activation Code',
      title: 'Title',
      company: 'Company Name',
      organization: 'Organization',
      companySize: 'Company Size (Employees)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1,000-3,499',
        option8: '3,500-4,999',
        option9: '5,000-10,000',
        option10: '10,000+',
      },
      employees: 'employees',
      createMyTrialIn: 'Create My Trial in',
      us: 'United States',
      uk: 'United Kingdom',
      de: 'Germany',
      sg: 'Singapore',
      au: 'Australia',
      ca: 'Canada',
      jp: 'Japan',
      br: 'Brazil',
      in: 'India',
      ae: 'United Arab Emirates',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'Try the #1 Vulnerability Scanner for Free',
                subHeading: 'Find the Vulnerabilities on Your Network',
              },
              tio: {
                heading: 'Try the World’s First Cyber Exposure Platform for Free',
                subHeading: 'Accurately Identify, Investigate and Prioritize Vulnerabilities',
              },
            },
            nextBtn: 'Continue',
            backBtn: 'Go Back',
            currentStepLabel: 'Step 1 of 2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: 'Thank you <span class="filled-first-name"></span> for trying the #1 Vulnerability Scanner in the world!',
                subHeading: 'Complete the form and find the Vulnerabilities on Your Network',
              },
              tio: {
                heading: 'Thank you <span class="filled-first-name"></span> for trying the world’s first Cyber Exposure platform.',
                subHeading: 'Complete the form to start reducing your cyber risk today',
              },
            },
            nextBtn: 'Get Started',
            backBtn: 'Go Back',
            currentStepLabel: 'Step 2 of 2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'Get Started',
            backBtn: 'Go Back',
            currentStepLabel: 'Step 1 of 4',
          },
          {
            step: 2,
            nextBtn: 'Next',
            backBtn: 'Go Back',
            currentStepLabel: 'Step 2 of 4',
          },
          {
            step: 3,
            nextBtn: 'Next',
            backBtn: 'Go Back',
            currentStepLabel: 'Step 3 of 4',
          },
          {
            step: 4,
            nextBtn: 'Start My Trial',
            backBtn: 'Go Back',
            currentStepLabel: 'Step 4 of 4',
          },
        ],
      },
      buyNowLink: 'or <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">Buy Now</a> for <span class="nessus-bundle-price-one-year">$3,390.00</span> per year.',
      consentDisclosure: 'By registering for this trial license, Tenable may send you email communications regarding its products and services. You may opt out of receiving these communications at any time by using the unsubscribe link located in the footer of the emails delivered to you. You can also manage your Tenable email preferences by visiting the <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Subscription Management Page</a>.',
      consentFinePrint: 'Tenable will only process your personal data in accordance with its <a target="_blank" href="https://www.tenable.com/privacy-policy">Privacy Policy</a>.',
      essentialsOptIn: 'Check to receive updates from Tenable',
      sending: 'Sending...',
      submit: 'Get Started',
      thanks: 'Thanks!',
      nessusEvalThanks: 'Thanks! To start your trial, download and install Nessus.',
      nessusEmailNotice: 'Details regarding your trial will be sent to this email.',
      nessusThankYouMessage: 'Next, input the email address that you used to register here on the welcome screen.',
      nessusDownloadMessage: 'Download Now',
      nessusExtensionThankYouMessage: 'Your trial has been extended.',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: 'You will receive an email confirmation in the next few minutes with next steps.',
      errors: {
        oops: 'Oops!',
        emailBlank: 'Email address cannot be blank',
        emailInvalid: 'Email address is not valid',
        emailWorkDomain: 'Please use a work email address',
        blank: 'cannot be blank',
        invalidCharacters: 'contains invalid characters',
        consent: 'You must consent to the transfer of your personal data to proceed.',
        serverFail: 'Server Error. Please email ',
      },
    },
    it: {
      firstName: 'Nome',
      lastName: 'Cognome',
      businessEmail: 'Email aziendale',
      email: 'Email',
      phone: 'Telefono',
      code: 'Codice di attivazione',
      title: 'Ruolo',
      company: 'Nome azienda',
      organization: 'Organizzazione',
      companySize: 'Dimensioni azienda (N. dipendenti)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1.000-3.499',
        option8: '3.500-4.999',
        option9: '5.000-10.000',
        option10: '10.000+',
      },
      employees: 'dipendenti',
      createMyTrialIn: 'Crea la mia valutazione in',
      us: 'Stati Uniti',
      uk: 'Regno Unito',
      de: 'Germania',
      sg: 'Singapore',
      au: 'Australia',
      ca: 'Canada',
      jp: 'Giappone',
      br: 'Brasile',
      in: 'India',
      ae: 'Emirati Arabi Uniti',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'Prova lo scanner delle vulnerabilità numero 1 gratuitamente',
                subHeading: 'Trova le vulnerabilità sulla tua rete',
              },
              tio: {
                heading: 'Prova la prima piattaforma Cyber Exposure al mondo gratuitamente',
                subHeading: 'Identifica, indaga e assegna priorità alle vulnerabilità in modo accurato',
              },
            },
            nextBtn: 'Continua',
            backBtn: 'Torna indietro',
            currentStepLabel: 'Passaggio 1 di 2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: 'Grazie <span class="filled-first-name"></span> per aver provato lo scanner delle vulnerabilità numero 1 al mondo!',
                subHeading: 'Completa il modulo e trova le vulnerabilità sulla tua rete',
              },
              tio: {
                heading: 'Grazie <span class="filled-first-name"></span> per aver provato la prima piattaforma Cyber Exposure al mondo.',
                subHeading: 'Completa il modulo per iniziare a ridurre il tuo rischio informatico',
              },
            },
            nextBtn: 'Inizia',
            backBtn: 'Torna indietro',
            currentStepLabel: 'Passaggio 2 di 2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'Iniziare',
            backBtn: 'Torna indietro',
            currentStepLabel: 'Passaggio 1 di 4',
          },
          {
            step: 2,
            nextBtn: 'Prossimo',
            backBtn: 'Torna indietro',
            currentStepLabel: 'Passaggio 2 di 4',
          },
          {
            step: 3,
            nextBtn: 'Prossimo',
            backBtn: 'Torna indietro',
            currentStepLabel: 'Passaggio 3 di 4',
          },
          {
            step: 4,
            nextBtn: 'Inizia la mia prova',
            backBtn: 'Torna indietro',
            currentStepLabel: 'Passaggio 4 di 4',
          },
        ],
      },
      buyNowLink: 'o <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">Acquista ora</a> per <span class="nessus-bundle-price-one-year">$2.990</span> all\'anno.',
      consentDisclosure: "Una volta eseguita l'iscrizione per la licenza di prova, Tenable potrebbe inviare all'utente email relative a propri prodotti e servizi. Per interrompere l'invio delle email è possibile fare clic in qualunque momento sul link dell'opzione di annullamento dell'iscrizione che si trova in fondo alle email ricevute. È possibile inoltre gestire le preferenze relative alle email Tenable visitando la <a target=\"_blank\" href=\"https://info.tenable.com/SubscriptionManagement.html\">Pagina di gestione dell'iscrizione</a>.",
      consentFinePrint: "Tenable elaborerà i dati personali dell'utente unicamente nel modo descritto nell'Informativa sulla privacy.",
      essentialsOptIn: 'Ricevi informazioni da Tenable',
      sending: 'Invio in corso...',
      submit: 'Inizia',
      thanks: 'Grazie!',
      nessusEvalThanks: 'Grazie! Per iniziare la prova, scarica e installa Nessus.',
      nessusEmailNotice: 'I dettagli relativi alla tua valutazione verranno inviati a questo indirizzo email.',
      nessusThankYouMessage: 'Successivamente, inserisci nella schermata di benvenuto l\'indirizzo email utilizzato per registrarti qui.',
      nessusDownloadMessage: 'Scarica',
      nessusExtensionThankYouMessage: 'Il tuo periodo di prova è stato esteso.',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: 'Tra pochi minuti riceverai un\'email di conferma contenente i passaggi successivi.',
      errors: {
        oops: 'Attenzione!',
        emailBlank: "L'indirizzo email non può essere vuoto",
        emailInvalid: "L'indirizzo email non è valido",
        emailWorkDomain: 'Utilizzare un indirizzo email di lavoro',
        blank: 'non può essere vuoto',
        invalidCharacters: 'contiene caratteri non validi',
        consent: 'Per poter procedere è necessario acconsentire al trasferimento dei propri dati personali.',
        serverFail: "Errore del server. Inviare un'email a ",
      },
    },
    fr: {
      firstName: 'Prénom',
      lastName: 'Nom',
      businessEmail: 'Email professionnel',
      email: 'Email',
      phone: 'Téléphone',
      code: "Code d'activation",
      title: 'Fonction',
      company: 'Nom de la société',
      organization: 'Organisation',
      companySize: "Taille de l'entreprise (nb d'employés)",
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1 000-3 499',
        option8: '3 500-4 999',
        option9: '5 000-10 000',
        option10: '+ de 10 000',
      },
      employees: 'Employés',
      createMyTrialIn: 'Créer mon essai dans',
      us: 'États-Unis',
      uk: 'Royaume-Uni',
      de: 'Allemagne',
      sg: 'Singapour',
      au: 'Australie',
      ca: 'Canada',
      jp: 'Japon',
      br: 'Brésil',
      in: 'Inde',
      ae: 'Emirats Arabes Unis',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'Essayez gratuitement le scanner de vulnérabilités n° 1',
                subHeading: 'Détectez les vulnérabilités de votre réseau',
              },
              tio: {
                heading: 'Essayez gratuitement la première plateforme de Cyber Exposure au monde',
                subHeading: 'Identifiez, examinez et priorisez les vulnérabilités avec précision',
              },
            },
            nextBtn: 'Continuer',
            backBtn: 'Retour',
            currentStepLabel: 'Étape 1 sur 2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: 'Merci <span class="filled-first-name"></span> d\'essayer le scanner de vulnérabilités n° 1 au monde !',
                subHeading: 'Complétez le formulaire et détectez les vulnérabilités de votre réseau',
              },
              tio: {
                heading: 'Merci <span class="filled-first-name"></span> d\'essayer la première plateforme de Cyber Exposure au monde.',
                subHeading: 'Complétez le formulaire pour commencer à réduire votre cyber-risque',
              },
            },
            nextBtn: 'Commencer',
            backBtn: 'Retour',
            currentStepLabel: 'Étape 2 sur 2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'Commencer',
            backBtn: 'Retourner',
            currentStepLabel: 'Étape 1 sur 4',
          },
          {
            step: 2,
            nextBtn: 'Suivant',
            backBtn: 'Retourner',
            currentStepLabel: 'Étape 2 sur 4',
          },
          {
            step: 3,
            nextBtn: 'Suivant',
            backBtn: 'Retourner',
            currentStepLabel: 'Étape 3 sur 4',
          },
          {
            step: 4,
            nextBtn: 'Commencer mon essai',
            backBtn: 'Retourner',
            currentStepLabel: 'Étape 4 sur 4',
          },
        ],
      },
      buyNowLink: 'ou <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">achetez dès maintenant</a> pour <span class="nessus-bundle-price-one-year">2 990,00 $</span> par an.',
      consentDisclosure: "En vous inscrivant pour bénéficier de cette licence d'évaluation, vous autorisez Tenable à vous envoyer des e-mails concernant ses produits et services. Vous pouvez désactiver l'envoi de ces e-mails à tout moment en utilisant le lien de désabonnement situé dans le pied de page des e-mails. Vous pouvez également gérer vos préférences vis-à-vis des e-mails Tenable en vous rendant sur la <a target=\"_blank\" href=\"https://info.tenable.com/SubscriptionManagement.html\">page de gestion des abonnements</a>.",
      consentFinePrint: 'Tenable traitera vos données personnelles uniquement de la manière décrite dans sa Politique de confidentialité.',
      essentialsOptIn: 'Recevoir des informations de la part de Tenable',
      sending: 'Envoi en cours...',
      submit: 'Commencer',
      thanks: 'Merci !',
      nessusEvalThanks: 'Merci ! Pour démarrer votre essai, téléchargez et installez Nessus.',
      nessusEmailNotice: 'Les détails concernant votre évaluation seront envoyés à cette adresse e-mail.',
      nessusThankYouMessage: 'Ensuite, saisissez l\'adresse e-mail que vous avez utilisée pour vous inscrire ici, sur l\'écran de bienvenue.',
      nessusDownloadMessage: 'TTélécharger maintenant',
      nessusExtensionThankYouMessage: 'Votre essai a été prolongé.',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: 'Dans quelques minutes, vous recevrez une confirmation par e-mail pour réaliser les prochaines étapes.',
      errors: {
        oops: 'Désolé !',
        emailBlank: "Le champ de l'adresse e-mail doit être renseigné",
        emailInvalid: "L'adresse e-mail n'est pas valide",
        emailWorkDomain: 'Veuillez utiliser une adresse e-mail professionnelle',
        blank: 'doit être renseigné',
        invalidCharacters: 'contient des caractères non valides',
        consent: 'Vous devez accepter le transfert de vos données personnelles pour poursuivre.',
        serverFail: 'Erreur serveur. Veuillez envoyer un e-mail ',
      },
    },
    de: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      businessEmail: 'Geschäftliche E-Mail-Adresse',
      email: 'E-Mail-Adresse',
      phone: 'Telefon',
      code: 'Aktivierungscode',
      title: 'Titel',
      company: 'Name des Unternehmens',
      organization: 'Organisation',
      companySize: 'Unternehmensgröße (Mitarbeiterzahl)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1.000-3.499',
        option8: '3.500-4.999',
        option9: '5.000-10.000',
        option10: '10.000+',
      },
      employees: 'Mitarbeiter',
      createMyTrialIn: 'Meine Testversion erstellen in',
      us: 'USA',
      uk: 'Vereinigtes Königreich',
      de: 'Deutschland',
      sg: 'Singapur',
      au: 'Australien',
      ca: 'Kanada',
      jp: 'Japan',
      br: 'Brasilien',
      in: 'Indien',
      ae: 'Vereinigte Arabische Emirate',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'Testen Sie kostenlos die Nummer eins der Schwachstellen-Scanner',
                subHeading: 'Finden Sie die Schwachstellen in Ihrem Netzwerk',
              },
              tio: {
                heading: 'Testen Sie kostenlos weltweit erste Cyber Exposure-Plattform',
                subHeading: 'Schwachstellen genau identifizieren, untersuchen und priorisieren',
              },
            },
            nextBtn: 'Weiter',
            backBtn: 'Zurück',
            currentStepLabel: 'Schritt 1 von 2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: 'Vielen Dank <span class="filled-first-name"></span>, dass Sie die weltweite Nummer eins unter den Schwachstellen-Scannern getestet haben!',
                subHeading: 'Füllen Sie das Formular aus und finden Sie die Schwachstellen in Ihrem Netzwerk',
              },
              tio: {
                heading: 'Vielen Dank <span class="filled-first-name"></span>, dass Sie die weltweit erste Cyber Exposure-Plattform getestet haben.',
                subHeading: 'Füllen Sie das Formular aus und beginnen Sie mit der Reduzierung Ihres Cyberrisikos',
              },
            },
            nextBtn: 'Anmelden',
            backBtn: 'Zurück',
            currentStepLabel: 'Schritt 2 von 2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'Loslegen',
            backBtn: 'Geh zurück',
            currentStepLabel: 'Schritt 1 von 4 ',
          },
          {
            step: 2,
            nextBtn: 'Nächste',
            backBtn: 'Geh zurück',
            currentStepLabel: 'Schritt 2 von 4 ',
          },
          {
            step: 3,
            nextBtn: 'Nächste',
            backBtn: 'Geh zurück',
            currentStepLabel: 'Schritt 3 von 4 ',
          },
          {
            step: 4,
            nextBtn: 'Starten Sie meine Testversion',
            backBtn: 'Geh zurück',
            currentStepLabel: 'Schritt 4 von 4 ',
          },
        ],
      },
      buyNowLink: 'oder <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">Jetzt kaufen</a> für <span class="nessus-bundle-price-one-year">$2.990,00</span> USD pro Jahr.',
      consentDisclosure: 'Durch Ihre Registrierung für diese Testlizenz erklären Sie sich damit einverstanden, dass Tenable Ihnen Mitteilungen zu seinen Produkten und Services per E-Mail zusenden darf. Diese E-Mail-Mitteilungen können Sie jederzeit abbestellen, in dem Sie in der Fußzeile der erhaltenen E-Mails auf den Abmelde-Link klicken. Außerdem können Sie Ihre Einstellungen für E-Mails von Tenable verwalten, indem Sie die <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Seite zur Verwaltung abonnierter E-Mails</a> aufrufen.',
      consentFinePrint: 'Tenable verarbeitet Ihre personenbezogenen Daten gemäß seiner Datenschutzrichtlinie.',
      essentialsOptIn: 'Aktivieren, um Updates von Tenable zu erhalten',
      sending: 'Wird gesendet ...',
      submit: 'Anmelden',
      thanks: 'Danke!',
      nessusEvalThanks: 'Danke! Um Ihren Test zu starten, laden Sie Nessus herunter und installieren Sie das Programm.',
      nessusEmailNotice: 'Einzelheiten zu Ihrer Evaluierung werden an diese E-Mail-Adresse geschickt.',
      nessusThankYouMessage: 'Geben Sie dann im Begrüßungsbildschirm die E-Mail-Adresse ein, mit der Sie sich hier registriert haben.',
      nessusDownloadMessage: 'Jetzt herunterladen',
      nessusExtensionThankYouMessage: 'Ihre Testversion wurde verlängert.',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: 'In den nächsten Minuten erhalten Sie eine E-Mail-Bestätigung sowie die nächsten Schritte.',
      errors: {
        oops: 'Ups!',
        emailBlank: 'E-Mail-Adresse darf nicht leer sein',
        emailInvalid: 'E-Mail-Adresse ist ungültig',
        emailWorkDomain: 'Bitte verwenden Sie eine geschäftliche E-Mail-Adresse',
        blank: 'darf nicht leer sein',
        invalidCharacters: 'enthält ungültige Zeichen',
        consent: 'Um fortzufahren, müssen Sie der Übermittlung Ihrer personenbezogenen Daten zustimmen.',
        serverFail: 'Serverfehler. Bitte senden Sie eine E-Mail an ',
      },
    },
    'es-la': {
      firstName: 'Nombre',
      lastName: 'Apellido',
      businessEmail: 'Correo electrónico laboral',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      code: 'Código de activación',
      title: 'Cargo',
      company: 'Nombre de la empresa',
      organization: 'Organización',
      companySize: 'Tamaño de la empresa (No. de empleados)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1000-3499',
        option8: '3500-4999',
        option9: '5000-10 000',
        option10: 'Más de 10 000',
      },
      employees: 'Empleados',
      createMyTrialIn: 'Crear mi prueba en',
      us: 'Estados Unidos',
      uk: 'Reino Unido',
      de: 'Alemania',
      sg: 'Singapur',
      au: 'Australia',
      ca: 'Canadá',
      jp: 'Japón',
      br: 'Brasil',
      in: 'India',
      ae: 'Emiratos Árabes Unidos',
      nessusStep1Heading: 'Versión de prueba del Escáner de vulnerabilidades #1',
      nessusStep1SubHeading: 'Descubra las vulnerabilidades de su red',
      tioStep1Heading: 'Pruebe la plataforma de Cyber Exposure número uno gratuitamente',
      tioStep1SubHeading: 'Identifique, investigue y priorice vulnerabilidades de manera precisa',
      continueText: 'Continuar',
      step1Of2: 'Paso 1 de 2',
      step1: 'Volver',
      nessusStep2Heading: '¡Gracias <span class="filled-first-name"></span> por probar el Escáner de vulnerabilidades #1 en el mundo!',
      nessusStep2SubHeading: 'Complete el formulario y detecte las vulnerabilidades de su red',
      tioStep2Heading: 'Gracias <span class="filled-first-name"></span> por probar la plataforma de Cyber Exposure número uno.',
      tioStep2SubHeading: 'Complete el formulario y comience a reducir su riesgo cibernético',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'Versión de prueba del Escáner de vulnerabilidades #1',
                subHeading: 'Descubra las vulnerabilidades de su red',
              },
              tio: {
                heading: 'Pruebe la plataforma de Cyber Exposure número uno gratuitamente',
                subHeading: 'Identifique, investigue y priorice vulnerabilidades de manera precisa',
              },
            },
            nextBtn: 'Continuar',
            backBtn: 'Volver',
            currentStepLabel: 'Paso 1 de 2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: '¡Gracias <span class="filled-first-name"></span> por probar el Escáner de vulnerabilidades #1 en el mundo!',
                subHeading: 'Complete el formulario y detecte las vulnerabilidades de su red',
              },
              tio: {
                heading: 'Gracias <span class="filled-first-name"></span> por probar la plataforma de Cyber Exposure número uno.',
                subHeading: 'Complete el formulario y comience a reducir su riesgo cibernético',
              },
            },
            nextBtn: 'Empezar',
            backBtn: 'Volver',
            currentStepLabel: 'Paso 1 de 2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'Empezar',
            backBtn: 'Regresa',
            currentStepLabel: 'Paso 1 de 4 ',
          },
          {
            step: 2,
            nextBtn: 'Próximo',
            backBtn: 'Regresa',
            currentStepLabel: 'Paso 2 de 4 ',
          },
          {
            step: 3,
            nextBtn: 'Próximo',
            backBtn: 'Regresa',
            currentStepLabel: 'Paso 3 de 4 ',
          },
          {
            step: 4,
            nextBtn: 'Iniciar mi prueb',
            backBtn: 'Regresa',
            currentStepLabel: 'Paso 4 de 4 ',
          },
        ],
      },
      buyNowLink: 'o <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">Compre ahora</a> por <span class="nessus-bundle-price-one-year">$3,390.00</span> al año.',
      consentDisclosure: 'Al registrarse a esta licencia de prueba, Tenable podrá enviarle comunicaciones de correo electrónico con respecto a sus productos y servicios. Usted puede optar por no recibir estas comunicaciones en cualquier momento mediante el enlace Dar de baja suscripción, situado en el pie de página de los correos electrónicos que se le envían. También puede administrar sus preferencias de correos electrónicos de Tenable visitando la <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Página de administración de suscripción</a>.',
      consentFinePrint: 'Tenable solo procesará sus datos personales como se describe en su Política de privacidad.',
      essentialsOptIn: 'Marque para recibir actualizaciones de Tenable',
      sending: 'Enviando...',
      submit: 'Empezar',
      thanks: '¡Gracias!',
      nessusEvalThanks: '¡Gracias! Para iniciar su prueba, descargue e instale Nessus.',
      nessusEmailNotice: 'Los detalles de su evaluación se enviarán a este correo electrónico.',
      nessusThankYouMessage: 'A continuación, ingrese la dirección de correo electrónico que empleó para registrarse aquí en la pantalla de bienvenida.',
      nessusDownloadMessage: 'Descargar ahora',
      nessusExtensionThankYouMessage: 'Su prueba se ha extendido.',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: 'En unos minutos, usted recibirá un correo electrónico de confirmación donde vendrán los próximos pasos.',
      errors: {
        oops: '¡Huy!',
        emailBlank: 'La dirección de correo electrónico no puede estar en blanco',
        emailInvalid: 'La dirección de correo electrónico no es válida',
        emailWorkDomain: 'Use una dirección de correo electrónico laboral',
        blank: 'no puede estar en blanco',
        invalidCharacters: 'contiene caracteres no válidos',
        consent: 'A fin de proceder, debe proporcionar su consentimiento para la transferencia de sus datos personales.',
        serverFail: 'Error del servidor. Envíe un correo electrónico ',
      },
    },
    'zh-cn': {
      firstName: '名字',
      lastName: '姓氏',
      businessEmail: '商业电子邮件',
      email: '电子邮件',
      phone: '电话',
      code: '激活码',
      title: '职称',
      company: '公司名称',
      organization: '组织',
      companySize: '公司规模 (员工人数)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1,000-3,499',
        option8: '3,500-4,999',
        option9: '5,000-10,000',
        option10: '10,000 以上',
      },
      employees: '名员工',
      createMyTrialIn: '在此处申请试用版',
      us: '美国',
      uk: '英国',
      de: '德国',
      sg: '新加坡',
      au: '澳大利亚',
      ca: '加拿大',
      jp: '日本',
      br: '巴西',
      in: '印度',
      ae: '阿拉伯联合酋长国',
      nessusStep2Heading: '感谢您<span class="filled-first-name"></span>试用全球排名第一的漏洞扫描程序！',
      nessusStep2SubHeading: '填写表格并找到您网络中的漏洞',
      tioStep2Heading: '感谢您<span class="filled-first-name"></span>试用全球首个 Cyber Exposure 平台。.',
      tioStep2SubHeading: '填写表格以开始减少网络安全风险',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'T免费试用排名第一的漏洞扫描程序',
                subHeading: '找到您网络中的漏洞',
              },
              tio: {
                heading: '免费试用全球首个 Cyber Exposure 平台',
                subHeading: '准确识别、研究漏洞并对其进行优先级分析。',
              },
            },
            nextBtn: '继续',
            backBtn: '返回',
            currentStepLabel: '第 1 步，共 2 步',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: '感谢您<span class="filled-first-name"></span>试用全球排名第一的漏洞扫描程序！',
                subHeading: '填写表格并找到您网络中的漏洞',
              },
              tio: {
                heading: '感谢您<span class="filled-first-name"></span>试用全球首个 Cyber Exposure 平台。.',
                subHeading: '填写表格以开始减少网络安全风险',
              },
            },
            nextBtn: '开始',
            backBtn: '返回',
            currentStepLabel: '第 2 步，共 2 步',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: '开始使用',
            backBtn: '回去',
            currentStepLabel: '第 1 步（共 4 步）',
          },
          {
            step: 2,
            nextBtn: '下一个',
            backBtn: '回去',
            currentStepLabel: '第 2 步（共 4 步）',
          },
          {
            step: 3,
            nextBtn: '下一个',
            backBtn: '回去',
            currentStepLabel: '第 3 步（共 4 步）',
          },
          {
            step: 4,
            nextBtn: '开始我的试用',
            backBtn: '回去',
            currentStepLabel: '第 4 步（共 4 步）',
          },
        ],
      },
      buyNowLink: '也可 <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">立即购买</a>，每年 <span class="nessus-bundle-price-one-year">$3,390.00</span>。',
      consentDisclosure: '注册此试用版许可证即表明 Tenable 可以向您发送有关其产品和服务的电子邮件。您随时可以使用发送给您的电子邮件页脚中的取消订阅链接选择不接收这些邮件。您也可以通过访问<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">订阅管理页面</a>来管理您的 Tenable 电子邮件首选项。',
      consentFinePrint: 'Tenable 仅按其隐私政策所述处理您的个人数据。',
      essentialsOptIn: '勾选表示同意接收 Tenable 更新内容',
      sending: '发送中...',
      submit: '开始',
      thanks: '谢谢！',
      nessusEvalThanks: '谢谢！要开始试用，请下载并安装 Nessus。',
      nessusEmailNotice: '有关您的评估详细信息将发送到此电子邮件。',
      nessusThankYouMessage: '接着，在欢迎屏幕中输入用于在此处注册的电子邮件地址。',
      nessusDownloadMessage: '立即下载',
      nessusExtensionThankYouMessage: '您的试用期已延长。',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: '几分钟后，您会收到电子邮件确认和后续步骤',
      errors: {
        oops: '糟糕！',
        emailBlank: '邮箱地址不能为空',
        emailInvalid: '邮箱地址无效',
        emailWorkDomain: '请填写工作邮箱地址',
        blank: '不能为空',
        invalidCharacters: '包含无效字符',
        consent: '您必须同意传输您的个人数据才能继续。',
        serverFail: '服务器错误。 发送邮件 ',
      },
    },
    'zh-tw': {
      firstName: '名字',
      lastName: '姓氏',
      businessEmail: '商用電子郵件',
      email: '電子郵件',
      phone: '電話號碼',
      code: '啟動碼',
      title: '職稱',
      company: '公司名稱',
      organization: '組織',
      companySize: '公司規模 (員工人數)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1,000-3,499',
        option8: '3,500-4,999',
        option9: '5,000-10,000',
        option10: '10,000 以上',
      },
      employees: '員工人數',
      createMyTrialIn: '選擇建立試用版的國家',
      us: '美國',
      uk: '英國',
      de: '德國',
      sg: '新加坡',
      au: '澳洲',
      ca: '加拿大',
      jp: '日本',
      br: '巴西',
      in: '印度',
      ae: '阿拉伯聯合大公國',
      nessusStep2Heading: '感謝您<span class="filled-first-name"></span>適用全球排名第一的弱點掃描器！',
      nessusStep2SubHeading: '填妥表單，並找出您網路上的弱點',
      tioStep2Heading: '感謝您<span class="filled-first-name"></span>試用全球第一的 Cyber Exposure 平台。',
      tioStep2SubHeading: '填妥表單，開始減少您的網路風險',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: '免費試用排名第一的弱點掃描器',
                subHeading: '找出您網路上的弱點',
              },
              tio: {
                heading: '免費試用全球第一的 Cyber Exposure 平台',
                subHeading: '精確地判別、調查並排定處理弱點的優先順序',
              },
            },
            nextBtn: '繼續',
            backBtn: '返回',
            currentStepLabel: '第 1 步（共 2 步）',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: '感謝您<span class="filled-first-name"></span>適用全球排名第一的弱點掃描器！',
                subHeading: '填妥表單，並找出您網路上的弱點',
              },
              tio: {
                heading: '感謝您<span class="filled-first-name"></span>試用全球第一的 Cyber Exposure 平台。',
                subHeading: '填妥表單，開始減少您的網路風險',
              },
            },
            nextBtn: '開始使用',
            backBtn: '返回',
            currentStepLabel: '第 2 步（共 2 步）',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: '開始使用',
            backBtn: '回去',
            currentStepLabel: '第 1 步（共 4 步）',
          },
          {
            step: 2,
            nextBtn: '下一個',
            backBtn: '回去',
            currentStepLabel: '第 2 步（共 4 步）',
          },
          {
            step: 3,
            nextBtn: '下一個',
            backBtn: '回去',
            currentStepLabel: '第 3 步（共 4 步）',
          },
          {
            step: 4,
            nextBtn: '開始我的試用',
            backBtn: '回去',
            currentStepLabel: '第 4 步（共 4 步）',
          },
        ],
      },
      buyNowLink: '或 <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">立即購買</a>，每年只要 <span class="nessus-bundle-price-one-year">$3,390.00</span>。',
      consentDisclosure: '註冊本試用授權後，Tenable 可能會寄給您有關其產品與服務的電子郵件通訊。您可以利用傳送給您的電子郵件下方註明的取消訂閱連結，隨時選擇取消接收這類通訊。您也可以造訪<a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">訂閱管理頁面</a>來管理您的 Tenable 電子郵件偏好設定。',
      consentFinePrint: 'Tenable 將僅依照其隱私政策所述來處理您的個人資料。',
      essentialsOptIn: '勾選以接收 Tenable 的最新消息',
      sending: '傳送中...',
      submit: '開始使用',
      thanks: '謝謝您！',
      nessusEvalThanks: '謝謝您！如要開始試用，請下載並安裝 Nessus。',
      nessusEmailNotice: '我們會將關於您評估軟體的詳細資訊寄到這個電子郵件地址。',
      nessusThankYouMessage: '接下來，請在歡迎畫面中輸入您在此用來註冊的電子郵件地址。',
      nessusDownloadMessage: '立即下載',
      nessusExtensionThankYouMessage: '您的試用期已延長。',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: '幾分鐘之後，您就會收到電子郵件確認及接下來的步驟。',
      errors: {
        oops: '糟糕！',
        emailBlank: '電子郵件地址不得為空白',
        emailInvalid: '電子郵件地址無效',
        emailWorkDomain: '請使用工作電子郵件地址',
        blank: '不得為空白',
        invalidCharacters: '包含無效字元',
        consent: '您必須同意傳輸您的個人資料才能繼續。',
        serverFail: '伺服器錯誤。 請寄送電子郵件 ',
      },
    },
    jp: {
      firstName: '名',
      lastName: '姓',
      businessEmail: '電子メール（ビジネス）',
      email: '電子メール',
      phone: '電話',
      code: 'アクティベーションコード',
      title: '役職',
      company: '会社名',
      organization: '組織',
      companySize: '企業規模 (従業員数)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1,000-3,499',
        option8: '3,500-4,999',
        option9: '5,000-10,000',
        option10: '10,000 以上',
      },
      employees: '従業員',
      createMyTrialIn: '試用する国',
      us: '米国',
      uk: '英国',
      de: 'ドイツ',
      sg: 'シンガポール',
      au: 'オーストラリア',
      ca: 'カナダ',
      jp: '日本',
      br: 'ブラジル',
      in: 'インド',
      ae: 'アラブ首長国連邦',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: '業界首位の脆弱性スキャン機能を<br>無料でお試しください',
                subHeading: 'ネットワークに潜む脆弱性を検出',
              },
              tio: {
                heading: '世界初の Cyber Exposure プラットフォームを無料でお試しください',
                subHeading: '脆弱性を正確に特定、調査、優先度付けしましょう',
              },
            },
            nextBtn: '次へ',
            backBtn: '戻る',
            currentStepLabel: 'ステップ1/2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: '<span class="filled-first-name"></span>様、世界 No.1の脆弱性スキャナをお試しいただきありがとうございます。',
                subHeading: 'フォームに記入して、ネットワークに潜む上の脆弱性を検出しましょう。',
              },
              tio: {
                heading: '<span class="filled-first-name"></span>様、世界初の Cyber Exposure Platform をお試しいただきありがとうございます。',
                subHeading: 'フォームに記入して、サイバーリスクを軽減し始めましょう。',
              },
            },
            nextBtn: '送信して試用を開始する',
            backBtn: '戻る',
            currentStepLabel: 'ステップ2/2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'はじめる',
            backBtn: '戻る',
            currentStepLabel: 'ステップ1/4',
          },
          {
            step: 2,
            nextBtn: '次へ',
            backBtn: '戻る',
            currentStepLabel: 'ステップ2/4',
          },
          {
            step: 3,
            nextBtn: '次へ',
            backBtn: '戻る',
            currentStepLabel: 'ステップ3/4',
          },
          {
            step: 4,
            nextBtn: 'トライアルを開始',
            backBtn: '戻る',
            currentStepLabel: 'ステップ4/4',
          },
        ],
      },
      buyNowLink: 'もしくは、<a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">今すぐご購入</a>ください (<span class="nessus-bundle-price-one-year">2,990.00ドル/年</span>)。',
      consentDisclosure: 'このトライアルライセンスにご登録いただいた場合、Tenableが製品およびサービスに関するご案内メールをお客様に送信することがあります。メール配信を希望されない場合は、ご案内メール下部のフッター部分に記載されている登録解除リンクからいつでも停止することができます。 <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">サブスクリプション管理ページ</a>からTenableのメール設定を管理することも可能です。',
      consentFinePrint: 'Tenableは、プライバシーポリシーに記載されている方法でのみお客様の個人情報を処理します。',
      essentialsOptIn: 'Tenableから最新情報を受け取る',
      sending: '送信中...',
      submit: '送信して試用を開始する',
      thanks: 'ありがとうございます！',
      nessusEvalThanks: 'ありがとうございます！トライアルを開始するには、まず Nessus をダウンロードしてインストールします。',
      nessusEmailNotice: '評価に関する詳細はこのメールアドレス宛に送信され ます。',
      nessusThankYouMessage: '次に、ここのようこその画面で登録したメールアドレスを入力します。',
      nessusDownloadMessage: '今すぐダウンロードするド',
      nessusExtensionThankYouMessage: 'トライアルが延長されました。',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: '次のステップと確認メールが数分後に届きます。',
      errors: {
        oops: 'おっとっと！',
        emailBlank: 'メールアドレスは必須です',
        emailInvalid: 'メールアドレスが無効です',
        emailWorkDomain: '勤務先のメールアドレスをご使用ください',
        blank: 'この欄は必須です',
        invalidCharacters: '無効な文字を含んでいます',
        consent: '個人情報の転送に同意いただく必要があります。',
        serverFail: 'サーバーエラー。 メールしてください ',
      },
    },
    'pt-br': {
      firstName: 'Nome',
      lastName: 'Sobrenome',
      businessEmail: 'E-mail comercial',
      email: 'Email',
      phone: 'Telefone',
      code: 'Código de ativação',
      title: 'Cargo',
      company: 'Nome da empresa',
      organization: 'Organização',
      companySize: 'Tamanho da empresa (N.º de funcionários)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1.000-3.499',
        option8: '3.500-4.999',
        option9: '5.000-10.000',
        option10: '+ de 10.000',
      },
      employees: 'funcionários',
      createMyTrialIn: 'Criar minha avaliação em',
      us: 'Estados Unidos',
      uk: 'Reino Unido',
      de: 'Alemanha',
      sg: 'Singapura',
      au: 'Austrália',
      ca: 'Canadá',
      jp: 'Japão',
      br: 'Brasil',
      in: 'Índia',
      ae: 'Emirados Árabes Unidos',
      nessusStep2Heading: 'Obrigado <span class="filled-first-name"></span> por testar o scanner de vulnerabilidades n.º 1 no mundo!',
      nessusStep2SubHeading: 'Preencha o formulário e encontre as vulnerabilidades na sua rede',
      tioStep2Heading: 'Obrigado <span class="filled-first-name"></span> por testar a primeira plataforma de Cyber Exposure do mundo.',
      tioStep2SubHeading: 'Prencha o formulário para começar a reduzir seu risco cibernético',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'Teste o scanner de vulnerabilidades n.º 1 gratuitamente',
                subHeading: 'Encontre as vulnerabilidades em sua rede',
              },
              tio: {
                heading: 'Teste o primeira plataforma de Cyber Exposure do mundo gratuitamente',
                subHeading: 'Identifique, investigue e priorize as vulnerabilidades com precisão',
              },
            },
            nextBtn: 'Continuar',
            backBtn: 'Voltar',
            currentStepLabel: 'Etapa 1 de 2',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: 'Obrigado <span class="filled-first-name"></span> por testar o scanner de vulnerabilidades n.º 1 no mundo!',
                subHeading: 'Preencha o formulário e encontre as vulnerabilidades na sua rede',
              },
              tio: {
                heading: 'Obrigado <span class="filled-first-name"></span> por testar a primeira plataforma de Cyber Exposure do mundo.',
                subHeading: 'Prencha o formulário para começar a reduzir seu risco cibernético',
              },
            },
            nextBtn: 'Começar',
            backBtn: 'Voltar',
            currentStepLabel: 'Etapa 2 de 2',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'Iniciar',
            backBtn: 'Volte',
            currentStepLabel: 'Etapa 1 de 4',
          },
          {
            step: 2,
            nextBtn: 'Próximo',
            backBtn: 'Volte',
            currentStepLabel: 'Etapa 2 de 4',
          },
          {
            step: 3,
            nextBtn: 'Próximo',
            backBtn: 'Volte',
            currentStepLabel: 'Etapa 3 de 4',
          },
          {
            step: 4,
            nextBtn: 'Iniciar meu teste',
            backBtn: 'Volte',
            currentStepLabel: 'Etapa 4 de 4',
          },
        ],
      },
      buyNowLink: 'ou <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">Compre agora</a> por <span class="nessus-bundle-price-one-year">US$ 2.990,00</span> por ano.',
      consentDisclosure: 'Ao assinar esta licença de demonstração, você poderá receber e mails sobre produtos e serviços da Tenable. Você pode optar por não receber tais comunicados quando quiser, usando o link de cancelamento de assinatura que aparece no rodapé desses e mails. Você também pode editar suas preferências de e mail acessando a <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">página de gerenciamento de assinaturas</a>.',
      consentFinePrint: 'A Tenable só processará os seus dados pessoais do modo descrito na Política de Privacidade.',
      essentialsOptIn: 'Marque para receber atualizações da Tenable',
      sending: 'Enviando...',
      submit: 'Começar',
      thanks: 'Obrigado!',
      nessusEvalThanks: 'Obrigado! Para iniciar sua avaliação, baixe e instale o Nessus.',
      nessusEmailNotice: 'Os detalhes sobre esta avaliação serão enviados a este e-mail.',
      nessusThankYouMessage: 'Em seguida, insira o endereço de e-mail que você usou para se registrar na tela de boas-vindas.',
      nessusDownloadMessage: 'Baixe agora',
      nessusExtensionThankYouMessage: 'Seu julgamento foi estendido.',
      nessusProToExpertThankYouMessage: 'Your product now has the Nessus Expert evaluation enabled.',
      tioThankYouMessage: 'Você receberá um e-mail de confirmação com as próximas etapas nos próximos minutos.',
      errors: {
        oops: 'Ops!',
        emailBlank: 'O endereço de e-mail não pode estar em branco',
        emailInvalid: 'O endereço de e-mail é inválido',
        emailWorkDomain: 'Utilize um endereço de e-mail de trabalho',
        blank: 'não pode estar em branco',
        invalidCharacters: 'contém caracteres inválidos',
        consent: 'Você deve concordar com a transferência dos seus dados pessoais antes de prosseguir.',
        serverFail: 'Erro no servidor. Enviar um e-mail ',
      },
    },
    kr: {
      firstName: '이름',
      lastName: '성',
      businessEmail: '업무용 이메일',
      email: '이메일',
      phone: '전화',
      code: '활성화 코드',
      title: '직함',
      company: '회사 이름',
      organization: '조직',
      companySize: '회사 규모(직원 수)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1,000-3,499',
        option8: '3,500-4,999',
        option9: '5,000-10,000',
        option10: '10,000+',
      },
      employees: '직원 수',
      createMyTrialIn: '내 평가판 지역',
      us: '미국',
      uk: '영국',
      de: '독일',
      sg: '싱가포르',
      au: '오스트레일리아',
      ca: '캐나다',
      jp: '일본',
      br: '브라질',
      in: '인도',
      ae: '아랍 에미리트',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: '무료로 최고의 취약성 스캐너 사용해 보기',
                subHeading: '네트워크에 취약성 찾기',
              },
              tio: {
                heading: '무료로 세계 최초의 사이버 보안 플랫폼 사용해 보기',
                subHeading: '취약성을 정확하게 식별하고 조사하고 우선 순위를 지정',
              },
            },
            nextBtn: '계속',
            backBtn: '뒤로',
            currentStepLabel: '1/2 단계',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: '<span class="filled-first-name"></span> 님, 세계 최고의 취약성 스캐너를 평가해 주셔서 감사합니다!',
                subHeading: '이 양식을 작성하여 네트워크의 취약성을 찾으십시오',
              },
              tio: {
                heading: '<span class="filled-first-name"></span> 님, 세계 최초의 사이버 보안 플랫폼을 평가해 주셔서 감사합니다.',
                subHeading: '이 양식을 작성하여 지금 사이버 위험을 줄이십시오',
              },
            },
            nextBtn: '시작하기',
            backBtn: '뒤로',
            currentStepLabel: '2/2 단계',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: '시작하기',
            backBtn: '뒤로',
            currentStepLabel: '1/4 단계',
          },
          {
            step: 2,
            nextBtn: '다음',
            backBtn: '뒤로',
            currentStepLabel: '2/4 단계',
          },
          {
            step: 3,
            nextBtn: '다음',
            backBtn: '뒤로',
            currentStepLabel: '3/4 단계',
          },
          {
            step: 4,
            nextBtn: '내 평가판 시작',
            backBtn: '뒤로',
            currentStepLabel: '4/4 단계',
          },
        ],
      },
      buyNowLink: '또는 <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=webtraffic" target="_blank">지금 구매</a>하십시오. 연간 <span class="nessus-bundle-price-one-year">$3,390.00</span>',
      consentDisclosure: '이 평가판 라이선스에 등록하면 Tenable에서 귀하에게 이 제품 및 서비스에 대해 이메일 커뮤니케이션을 보낼 수 있습니다. 받은 이메일의 바닥글에서 구독 취소 링크를 사용하여 언제든지 커뮤니케이션 수신을 옵트아웃할 수 있습니다. 또한 <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">구독 관리 페이지</a>를 방문하여 Tenable 이메일 기본 설정을 관리할 수 있습니다.',
      consentFinePrint: 'Tenable은 <a target="_blank" href="https://www.tenable.com/privacy-policy">개인 정보 보호 정책</a>에 따라서만 개인 정보를 처리합니다.',
      essentialsOptIn: '확인하여 Tenable에서 업데이트 받기',
      sending: '보내는 중...',
      submit: '시작하기',
      thanks: '감사합니다.',
      nessusEvalThanks: '감사합니다. 평가판을 시작하려면 Nessus를 다운로드하여 설치하십시오.',
      nessusEmailNotice: '평가판에 대한 세부 정보를 이 이메일에 보낼 것입니다.',
      nessusThankYouMessage: '다음으로 시작 화면에서 여기에 등록할 때 사용한 이메일 주소를 입력하십시오.',
      nessusDownloadMessage: '지금 다운로드',
      nessusExtensionThankYouMessage: '평가판이 연장되었습니다.',
      nessusProToExpertThankYouMessage: '이제 제품에 Nessus Expert 평가를 사용으로 설정했습니다.',
      tioThankYouMessage: '잠시 후에 다음 단계에 대한 이메일 확인을 받을 것입니다.',
      errors: {
        oops: '죄송합니다.',
        emailBlank: '이메일 주소는 비워있을 수 없습니다',
        emailInvalid: '이메일 주소가 유효하지 않습니다',
        emailWorkDomain: '업무용 이메일 주소를 사용하십시오',
        blank: '비워있을 수 없습니다',
        invalidCharacters: '무효한 문자를 포함합니다',
        consent: '계속하려면 개인 데이터의 이전에 동의해야 합니다.',
        serverFail: '서버 오류. Please email ',
      },
    },
    ar: {
      firstName: 'الاسم الأول',
      lastName: 'الاسم الأخير',
      businessEmail: 'البريد الإلكتروني للشركة',
      email: 'البريد الإلكتروني',
      phone: 'الهاتف',
      code: 'رمز التفعيل',
      title: 'اللقب',
      company: 'اسم الشركة',
      organization: 'المؤسسة',
      companySize: 'حجم الشركة (الموظفون)',
      companySizeOptions: {
        option1: '1-9',
        option2: '10-49',
        option3: '50-99',
        option4: '100-249',
        option5: '250-499',
        option6: '500-999',
        option7: '1,000-2,499',
        option8: '2,500-4,999',
        option9: '5,000-10,000',
        option10: '10,000+',
      },
      employees: 'الموظفون',
      createMyTrialIn: 'إنشاء نسختي التجريبية في',
      us: 'الولايات المتحدة',
      uk: 'المملكة المتحدة',
      de: 'ألمانيا',
      sg: 'سنغافورة',
      au: 'أستراليا',
      ca: 'كندا',
      jp: 'اليابان',
      br: 'البرازيل',
      in: 'الهند',
      ae: 'الأمارات العربية المتحدة',
      steps: {
        twoStep: [
          {
            step: 1,
            headings: {
              nessus: {
                heading: 'جرب برنامج فحص الثغرات الأمنية رقم ١ مجانًا',
                subHeading: 'اكتشف الثغرات الأمنية في شبكتك',
              },
              tio: {
                heading: 'جرب منصة التعرض للمخاطر الأولى في العالم مجانًا',
                subHeading: 'تحديد الثغرات والتحقيق فيها وتحديد أولوياتها بدقة',
              },
            },
            nextBtn: 'متابعة',
            backBtn: 'رجوع',
            currentStepLabel: 'خطوة ١ من ٢',
          },
          {
            step: 2,
            headings: {
              nessus: {
                heading: 'شكرًا <span class="filled-first-name"></span> على تجربة برنامج فحص الثغرات الأمنية الأول في العالم!',
                subHeading: 'أكمل النموذج وابحث عن الثغرات الأمنية على شبكتك',
              },
              tio: {
                heading: 'شكرًا <span class="filled-first-name"></span> على تجربة منصة التعرض للمخاطر الإلكترونية الأولى في العالم.',
                subHeading: 'أكمل النموذج للبدء في تقليل المخاطر الإلكترونية اليوم',
              },
            },
            nextBtn: 'البدء',
            backBtn: 'رجوع',
            currentStepLabel: 'خطوة ٢ من ٢',
          },
        ],
        fourStep: [
          {
            step: 1,
            nextBtn: 'البدء',
            backBtn: 'رجوع',
            currentStepLabel: 'خطوة ١ من ٤',
          },
          {
            step: 2,
            nextBtn: 'التالي',
            backBtn: 'رجوع',
            currentStepLabel: 'خطوة ٢ من ٤',
          },
          {
            step: 3,
            nextBtn: 'التالي',
            backBtn: 'رجوع',
            currentStepLabel: 'خطوة ٣ من ٤',
          },
          {
            step: 4,
            nextBtn: 'بدء تجربتي المجانية',
            backBtn: 'رجوع',
            currentStepLabel: 'خطوة ٤ من ٤',
          },
        ],
      },
      buyNowLink: 'أو <a href="https://store.tenable.com/1479/purl-NessusOneYear?x-promotion=SEMLanding" target="_blank">شراء الآن</a> بسعر <span class="nessus-bundle-price-one-year">$3,390.00</span> سنويًا.',
      consentDisclosure: 'بالتسجيل للحصول على هذا الترخيص التجريبي، قد ترسل لك Tenable اتصالات عبر البريد الإلكتروني بخصوص منتجاتها وخدماتها. ويمكنك إلغاء اشتراك  تلقي هذه الاتصالات في أي وقت باستخدام رابط إلغاء الاشتراك الموجود في نهاية رسائل البريد الإلكتروني التي تم إرسالها إليك. يمكنك أيضًا إدارة تفضيلات البريد الإلكتروني الخاصة بـ Tenable من خلال زيارة <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">ضفحة إدارة الاشتراك</a>.',
      consentFinePrint: 'لن تعالج Tenable بياناتك الشخصية إلا بما يتوافق مع  <a target="_blank" href="https://www.tenable.com/privacy-policy">سياسة الخصوصية</a>.',
      essentialsOptIn: 'حدد لتلقي تحديثات من Tenable',
      sending: 'جارِ الإرسال...',
      submit: 'البدء',
      thanks: 'شكرًا!',
      nessusEmailNotice: 'سيتم إرسال التفاصيل المتعلقة بـ EVAL إلى هذا البريد الإلكتروني.',
      nessusThankYouMessage: 'ستتلقى رسالة بريد إلكتروني في الدقائق القليلة القادمة تحتوي على تفاصيل حول كيفية تنشيط Nessus eval.',
      nessusExtensionThankYouMessage: 'تم تمديد فترتك التجريبية.',
      nessusProToExpertThankYouMessage: 'تم تمكين تقييم Nessus Expert لمنتجك الآن.',
      tioThankYouMessage: 'ستتلقى تأكيدًا عبر البريد الإلكتروني في الدقائق القليلة القادمة بالخطوات التالية.',
      errors: {
        oops: 'عفوًا!',
        emailBlank: 'لا يمكن ترك عنوان البريد الإلكتروني فارغًا',
        emailInvalid: 'عنوان البريد الإلكتروني غير صالح',
        emailWorkDomain: 'يرجى استخدام عنوان البريد الإلكتروني للعمل',
        blank: 'لا يمكن تركه فارغًا',
        invalidCharacters: 'يحتوي على حروف غير صالحة',
        consent: 'يجب عليك الموافقة على نقل بياناتك الشخصية للمتابعة.',
        serverFail: 'حدث خطأ في الخادم. يرجى إرسال بريد إلكتروني ',
      },
    },
  },

  generateFormHTML(
    formID,
    product,
    labelsHidden,
    strings,
    floatingLabel,
    edu,
    multiStepConfig,
    isFourStepForm = false,
    isTwoStepForm = false,
    isChooseTioApps = false,
    hideStepHeadings = false,
  ) {
    const hideLabel = labelsHidden ? 'class="visuallyhidden"' : '';
    const showPlaceholder = (fieldName) => labelsHidden ? `${fieldName}` : ''; // eslint-disable-line no-confusing-arrow
    const emailValue = this.escapeHTML(tnsTools.getParameterByName('email')) || ''; // eslint-disable-line no-undef

    const multiStepStyles = `
    <style>
      .step-status {
        text-align: center;
      }

      .two-step-form h2 {
        font-size: 1.5em;
      }

      .two-step-form h4 {
        font-size: 1.25em;
        margin: 1em 0;
      }

      .two-step-form .form-item {
        width: 100%;
      }

      .form-items-wrap-1,
      .form-items-wrap-2 {
        display: none;
      }

      .wrap-steps.step1 .form-items-wrap-1,
      .wrap-steps.step2 .form-items-wrap-2 {
        display: block;
      }

      .step-status {
        text-align: center;
      }
    </style>
  `;

    const firstNameFieldHtml = `
      <div class="form-item">
        <label for="${formID}-first_name" ${hideLabel}>${strings.firstName}</label>
        <input type="text" placeholder="${showPlaceholder(strings.firstName)}" name="first_name" id="${formID}-first_name" required>
        <div class="eval-error"></div>
      </div>
    `;

    const lastNameFieldHtml = `
      <div class="form-item">
        <label for="${formID}-last_name" ${hideLabel}>${strings.lastName}</label>
        <input type="text" placeholder="${showPlaceholder(strings.lastName)}" name="last_name" id="${formID}-last_name" required>
        <div class="eval-error"></div>
      </div>
    `;

    const businessEmailFieldHtml = `
      <div class="form-item">
        <label for="${formID}-email" ${hideLabel}>${edu ? strings.email : strings.businessEmail}</label>
        <input type="email" placeholder="${showPlaceholder(edu ? strings.email : strings.businessEmail)}" name="email" id="${formID}-email" value="${emailValue}" required>
        <div class="eval-error"></div>
        ${(product === 'nessus' || product === 'expert') ? `<p class="line"><small>${strings.nessusEmailNotice}</small></p>` : ''}
      </div>
    `;

    const phoneFieldHtml = product === 'essentials' ? '' : `
    <div class="form-item">
      <label for="${formID}-phone" ${hideLabel}>${strings.phone}</label>
      <input type="tel" placeholder="${showPlaceholder(strings.phone)}" name="phone" id="${formID}-phone" required>
      <div class="eval-error"></div>
    </div>
  `;

    const companyFieldHtml = (product === 'essentials' && !edu) ? '' : `
    <div class="form-item">
      <label for="${formID}-company-name" ${hideLabel}>${edu ? strings.organization : strings.company}</label>
      <input type="text" placeholder="${showPlaceholder(edu ? strings.organization : strings.company)}" name="company-name" id="${formID}-company-name" required>
      <div class="eval-error"></div>
    </div>
  `;

    const jobTitleFieldHtml = (product === 'essentials') ? '' : `
      <div class="form-item">
        <label for="${formID}-job-title" ${hideLabel}>${strings.title}</label>
        <input type="text" placeholder="${showPlaceholder(strings.title)}" name="job-title" id="${formID}-job-title" required>
        <div class="eval-error"></div>
      </div>
    `;

    const nextStepButton = (step) => `
      <div class="wrap-btn textcenter">
        <a href="#" class="btn btn-primary btn-large btn-block go-to-step-${step.step + 1} go-to-next-step animated hmt ${isTwoStepForm ? 'formBtn' : ''}">${step.nextBtn}</a>
      </div>
    `;

    const previousStepButton = (step) => `
      <a href="#" class=go-to-previous-step ${isTwoStepForm ? `go-back-${step.step - 1} formBtn` : ''}">${step.backBtn}</a>
    `;

    const stepHeadings = (step) => {
      if (hideStepHeadings) {
        return '';
      }

      return `
      ${step.heading ? `<h2>${step.heading}</h2>` : ''}
      ${step.subHeading ? `<h4>${step.subHeading}</h4>` : ''}
    `;
    };

    const createTrialInFieldHtml = (product === 'nessus' || product === 'essentials' || product === 'expert') ? '' : `
    <div class="form-item">
      <label for="${formID}-site" ${hideLabel}>${strings.createMyTrialIn}</label>
      <select name="site" id="${formID}-site" required>
        ${floatingLabel ? `<option value="">${showPlaceholder(`${strings.createMyTrialIn}: `)} </option>` : ''}
        <option value="us08">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.us}</option>
        <option value="ca01">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.ca}</option>
        <option value="uk-lon-1">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.uk}</option>
        <option value="eu-fra-1">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.de}</option>
        <option value="ap-sing-1">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.sg}</option>
        <option value="ap-syd-1">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.au}</option>
        <option value="jp01">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.jp}</option>
        <option value="br01">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.br}</option>
        <option value="in01">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.in}</option>
        ${(product === 'tio' || product === 'vm') && `<option value="uae01">${showPlaceholder(`${strings.createMyTrialIn}: `)} ${strings.ae}</option>`}
      </select>
      <div class="eval-error"></div>
    </div>
  `;

    const companySizeFieldHtml = (product === 'essentials') ? '' : `
      <div class="form-item">
        <label for="${formID}-company-size" ${hideLabel}>${strings.companySize}</label>
        <select name="company-size" id="${formID}-company-size" required>
          <option value="">${showPlaceholder(`${strings.companySize}: `)} </option>
          <option value="1-9">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option1}</option>
          <option value="10-49">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option2}</option>
          <option value="50-99">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option3}</option>
          <option value="100-249">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option4}</option>
          <option value="250-499">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option5}</option>
          <option value="500-999">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option6}</option>
          <option value="1,000-3,499">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option7}</option>
          <option value="3,500-4,999">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option8}</option>
          <option value="5,000-10,000">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option9}</option>
          <option value="10,000+">${showPlaceholder(`${strings.companySize}: `)}${strings.companySizeOptions.option10}</option>
        </select>
        <div class="eval-error"></div>
      </div>
    `;

    const consentCheckboxFieldHtml = `
      <div class="form-item input-checkbox consent">
        ${product === 'essentials' ? '' : `<p class="line hmb"><small>${strings.consentDisclosure}</small></p>`}
        ${product === 'essentials' ? `<label for="${formID}-essentials-opt-in"><input class="mr" name="essentials-opt-in" id="${formID}-essentials-opt-in" type="checkbox">${strings.essentialsOptIn}</label>` : ''}
        <p class="line"><small>${strings.consentFinePrint}</small></p>

        <input class="hidden" name="check-accept" id="${formID}-check-accept" value="">
      </div>
    `;

    const tioAppsCheckboxesFieldHtml = `
    <div class="form-item">
      <fieldset class="form-item">
        <legend><strong>Select Additional Apps to Evaluate:</strong></legend>
        <p class="line"><small>Select to trial additional apps for customer who already owns a paid Tenable VM. Leave empty to generate regular eval with all apps (VM, WAS, Lumin).</small></p>

        <label style="font-weight: 400;" for="${formID}-tio-apps-was"><input class="mr" name="tio-apps" id="${formID}-tio-apps-was" type="checkbox" value="was">Web Application Scanning</label>
        <label style="font-weight: 400;" for="${formID}-tio-apps-lumin"><input class="mr" name="tio-apps" id="${formID}-tio-apps-lumin" type="checkbox" value="lumin">Lumin</label>
      </fieldset>
    </div>
  `;

    const step1 = (step, className) => (step ? `
      <div class="form-step-1 ${className || ''}" data-step="1">
        ${isTwoStepForm ? `
          ${(product === 'nessus' || product === 'expert') ? stepHeadings(step.headings.nessus) : ''}
          ${product === 'tio' ? stepHeadings(step.headings.tio) : ''}

          <div class="clearfix">
            <div class="onehalf">
              ${firstNameFieldHtml}
            </div>
            <div class="onehalf last">
              ${lastNameFieldHtml}
            </div>
          </div>
          <div class="clearfix">
            <div class="onehalf">
              ${businessEmailFieldHtml}
            </div>
            <div class="onehalf last">
              ${companyFieldHtml}
            </div>
          </div>
        ` : ''}

        ${isFourStepForm ? `
          ${stepHeadings(step)}
          <div class="step-status hmt">${step.currentStepLabel}</div>
          ${firstNameFieldHtml}
          ${lastNameFieldHtml}
        ` : ''}

        ${nextStepButton(step)}
      </div>
    ` : '');

    const step2 = (step, className) => (step ? `
      <div class="form-step-2 hidden ${className || ''}" data-step="2">
        ${isTwoStepForm ? `
          ${(product === 'nessus' || product === 'expert') ? stepHeadings(step.headings.nessus) : ''}
          ${product === 'tio' ? stepHeadings(step.headings.tio) : ''}

          <div class="clearfix">
            <div class="onehalf">
              ${jobTitleFieldHtml}
            </div>
            <div class="onehalf last">
              ${phoneFieldHtml}
            </div>
          </div>
          ${companySizeFieldHtml}
          ${createTrialInFieldHtml}
          ${consentCheckboxFieldHtml}

          <div class="wrap-btn textcenter">
            ${previousStepButton(step)}
            <input type="submit" id="${formID}-eval-submit" class="btn btn-large btn-block animated hmt" value="${step.nextBtn}">
            <div class="step-status hmt">${step.currentStepLabel}</div>
          </div>
        ` : ''}

        ${isFourStepForm ? `
          ${stepHeadings(step)}
          <div class="step-status hmt">${step.currentStepLabel}</div>
          ${businessEmailFieldHtml}
          ${phoneFieldHtml}
          <div class="wrap-btn textcenter">
            ${previousStepButton(step)}
            ${nextStepButton(step)}
          </div>
      ` : ''}
      </div>
    ` : '');

    const step3 = (step, className) => (step ? `
      <div class="form-step-3 hidden ${className || ''}" data-step="3">
        ${stepHeadings(step)}
        <div class="step-status hmt">${step.currentStepLabel}</div>
        ${jobTitleFieldHtml}
        ${companyFieldHtml}

        <div class="wrap-btn textcenter">
          ${previousStepButton(step)}
          ${nextStepButton(step)}
        </div>
      </div>
    ` : '');

    const step4 = (step, className) => (step ? `
      <div class="form-step-4 hidden ${className || ''}" data-step="4">
        ${stepHeadings(step)}
        <div class="step-status hmt">${step.currentStepLabel}</div>
        ${companySizeFieldHtml}
        ${createTrialInFieldHtml}
        ${consentCheckboxFieldHtml}

        <div class="wrap-btn textcenter">
          ${previousStepButton(step)}
          <input type="submit" id="${formID}-eval-submit" class="btn btn-large btn-block animated hmt" value="${step.nextBtn}">
        </div>
      </div>
    ` : '');

    const multStepForm = `
      <div class="wrap-steps" data-current-step="1">
        ${step1(multiStepConfig.steps[0])}
        ${step2(multiStepConfig.steps[1])}
        ${step3(multiStepConfig.steps[2])}
        ${step4(multiStepConfig.steps[3])}
      </div>
      ${product === 'nessus' ? `<div class="buy-now-link-wrapper textcenter hmt">${strings.buyNowLink}</div>` : ''}
    `;

    const twoStepForm = `
      <div class="wrap-steps step1" data-current-step="1">
        ${step1(multiStepConfig.steps[0], 'form-items-wrap-1')}
        ${step2(multiStepConfig.steps[1], 'form-items-wrap-2')}
      </div>
      ${product === 'nessus' ? `<div class="buy-now-link-wrapper textcenter hmt">${strings.buyNowLink}</div>` : ''}
    `;

    const singleStepForm = `
      <div class="clearfix">
        <div class="onehalf">
          ${firstNameFieldHtml}
        </div>
        <div class="onehalf last">
          ${lastNameFieldHtml}
        </div>
      </div>

      ${businessEmailFieldHtml}
      ${phoneFieldHtml}
      ${jobTitleFieldHtml}
      ${companyFieldHtml}
      ${companySizeFieldHtml}
      ${createTrialInFieldHtml}
      ${isChooseTioApps ? tioAppsCheckboxesFieldHtml : ''}
      ${consentCheckboxFieldHtml}

      <div class="wrap-btn textcenter">
        <input type="submit" id="${formID}-eval-submit" class="btn btn-large btn-block animated hmt" value="${strings.submit}">
      </div>
    `;

    return `
      ${isFourStepForm ? multiStepStyles : ''}
      <div id="${formID}-error-msg" class="error-msg hidden line"><strong class="white-text">${strings.errors.oops}</strong><br></div>
        <form id="${formID}-eval-form" autocomplete="on" novalidate class="${isFourStepForm ? 'multi-step-form' : ''} ${isTwoStepForm ? 'two-step-form' : ''} ${floatingLabel ? 'eval-floating-label' : ''}">
          ${isFourStepForm ? multStepForm : ''}
          ${isTwoStepForm ? twoStepForm : ''}
          ${!isFourStepForm && !isTwoStepForm ? singleStepForm : ''}
        </form>

        <div class="confirmation-box textcenter hidden" id="${formID}-confirmation">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="6em" stroke="#00F0FF" stroke-width="1.5" fill="transparent">
            <title>Check</title>
            <polygon points="58.99 18.24 32.49 44.74 21.01 33.26 12.5 41.77 32.49 61.76 67.5 26.75 58.99 18.24" stroke-miterlimit="10"/>
            <line x1="65.83" y1="25.08" x2="30.82" y2="60.09" stroke-miterlimit="10"/>
            <circle cx="40" cy="40" r="36.53" stroke-linecap="round" stroke-miterlimit="10"/>
          </svg>

          <h2 style="font-size: 1.6em;" class="mb">
            ${(product === 'nessus' || product === 'essentials' || product === 'expert') ? strings.nessusEvalThanks : strings.thanks}
          </h2>
          ${(product === 'nessus' || product === 'essentials' || product === 'expert')
    ? `<p><a class="btn btn-2017 btn-2017--orange" href="https://www.tenable.com/downloads/nessus?utm_source=nessus-trial-thank-you-update">${strings.nessusDownloadMessage}</a></p><p>${strings.nessusThankYouMessage}</p>`
    : `<p>${strings.tioThankYouMessage}</p>`}
        </div>
      </div>
    `;
  },

  generateExtensionFormHTML(formID, product, labelsHidden, strings, floatingLabel) {
    const hideLabel = labelsHidden ? 'class="visuallyhidden"' : '';
    const showPlaceholder = (fieldName) => labelsHidden ? `${fieldName}` : ''; // eslint-disable-line no-confusing-arrow

    return `<div id="${formID}-error-msg" class="error-msg hidden line"><strong class="white-text">${strings.errors.oops}</strong><br></div>

      <form id="${formID}-eval-form" autocomplete="on" novalidate class="${floatingLabel ? 'eval-floating-label' : ''}">
          <div class="form-item">
            <label for="${formID}-email" ${hideLabel}>${strings.email}</label>
            <input type="email" placeholder="${showPlaceholder(strings.email)}" name="email" id="${formID}-email" value="">
            <div class="eval-error"></div>
          </div>

          <div class="form-item">
            <label for="${formID}-code" ${hideLabel}>${strings.code}</label>
            <input type="text" placeholder="${showPlaceholder(strings.code)}" name="code" id="${formID}-code" value="" required>
            <div class="eval-error"></div>
          </div>

          <input class="hidden" name="check-accept" id="${formID}-check-accept" value="">

          <div class="wrap-btn textcenter">
            <input type="submit" id="${formID}-eval-submit" class="btn btn-large btn-block animated hmt" value="${strings.submit}">
          </div>
        </div>
      </div>
    </form>

    <div class="confirmation-box textcenter hidden" id="${formID}-confirmation">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="8em" stroke="#3fae29" fill="transparent">
        <title>Check</title>
        <polygon points="58.99 18.24 32.49 44.74 21.01 33.26 12.5 41.77 32.49 61.76 67.5 26.75 58.99 18.24" stroke-miterlimit="10"/>
        <line x1="65.83" y1="25.08" x2="30.82" y2="60.09" stroke-miterlimit="10"/>
        <circle cx="40" cy="40" r="36.53" stroke-linecap="round" stroke-miterlimit="10"/>
      </svg>

      <h2 class="mt">${strings.thanks}</h2>
      <p>${product === 'nessus-pro-to-expert' ? strings.nessusProToExpertThankYouMessage : strings.nessusExtensionThankYouMessage}</p>
    </div>
    `;
  },

  getProductName(id) {
    const products = {
      tio: 'Tenable.io',
      vm: 'Tenable.io',
      consec: 'Container Security',
      was: 'Web App Scanning',
      lumin: 'Lumin',
      nessus: 'Nessus Pro',
      cns: 'Tenable.cs',
      essentials: 'Nessus Essentials',
      expert: 'Nessus Expert',
    };

    return products[id];
  },

  showErr(errMsg, msg) {
    console.error(msg);
    errMsg.classList.remove('hidden');
    errMsg.innerHTML += `${msg} <br>`;
  },

  showErrBelowField(fieldVar, msg) {
    fieldVar.parentElement.classList.add('invalid');
    fieldVar.parentElement.querySelector('.eval-error').innerText = msg;
  },

  fireGTMEvent(category, action, label) {
    if (this.dev) { return; }

    /* eslint-disable no-undef */
    if (dataLayer) {
      dataLayer.push({
        event: 'gaEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      });
    }
    /* eslint-enable */
  },

  firePathFactoryConversion(config) {
    if (window.parent !== window.top) {
      const domain = window.location.hostname;
      const targetOrigin = domain.includes('tenable.com') ? `https://${domain}` : 'https://www.tenable.com';

      window.parent.postMessage({
        conversionUrl: document.location.href,
        referrer: document.referrer,
        email: config.email.value,
        lookbookExternalForm: true,
      }, targetOrigin);
    }
  },

  fireConversionTracking(config, uuid) {
    if (this.dev) { return; }

    this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Success', `${uuid || Date.now()} ${config.appsArr.toString()}`);

    const conversionLabels = {
      tio: 'DRj0CNGe2W0Q86vz_gM',
      vm: 'DRj0CNGe2W0Q86vz_gM',
      consec: 'ElPtCLWdqHQQ86vz_gM',
      was: '0Bi5CIefqHQQ86vz_gM',
      nessus: 'W3xFCK2UzQkQ86vz_gM',
    };

    window.google_trackConversion({
      google_conversion_id: 1071437299,
      google_conversion_label: conversionLabels[config.product],
      google_remarketing_only: false,
    });

    this.firePathFactoryConversion(config);
  },

  checkEmailDomain(value, fieldVar, config) {
    const domainEntered = value.split('@')[1];
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    if (value.length === 0 && config.product === 'nessus-pro-to-expert') {
      return true;
    }

    if (value.length === 0) {
      config.email.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, config.strings.errors.emailBlank);
      } else {
        this.showErr(config.errMsg, config.strings.errors.emailBlank);
      }
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Client Fail', 'Email address cannot be blank');
      return false;
    }

    if (!(emailRegExp.test(value))) {
      config.email.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, config.strings.errors.emailInvalid);
      } else {
        this.showErr(config.errMsg, config.strings.errors.emailInvalid);
      }
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Client Fail', 'Email address is not valid');
      return false;
    }

    /// Don't check banned domains for Essentials
    if (config.product === 'essentials' || config.product === 'nessus-extension' || config.product === 'nessus-extension-extended') return true;

    if (this.bannedDomains.indexOf(domainEntered.toLowerCase()) > -1) {
      config.email.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, config.strings.errors.emailWorkDomain);
      } else {
        this.showErr(config.errMsg, config.strings.errors.emailWorkDomain);
      }
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Client Fail', 'Please use a work email address');
      return false;
    }

    return true;
  },

  checkFieldValue(value, fieldVar, fieldName, config) {
    const badChars = /[|%"<>@\\]/g;
    const badJS = /javascript:/i;

    if (!fieldVar) return true;

    if (value.length < 1) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config.strings.errors.blank}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config.strings.errors.blank}`);
      }
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Client Fail', `${fieldName} cannot be blank`);
      return false;
    }

    if (badChars.test(value) || badJS.test(value)) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config.strings.errors.invalidCharacters}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config.strings.errors.invalidCharacters}`);
      }
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Client Fail', `${fieldName} contains invalid characters`);
      return false;
    }

    return true;
  },

  checkSelectValue(value, fieldVar, fieldName, config) {
    if (!fieldVar) return true;
    if (value.length < 1) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config.strings.errors.blank}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config.strings.errors.blank}`);
      }
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Client Fail', `${fieldName} cannot be blank`);
      return false;
    }

    return true;
  },

  escapeHTML(html) {
    const escape = document.createElement('textarea');
    escape.textContent = html;
    return escape.innerHTML;
  },

  showSendingState(config) {
    config.submitBtn.value = config.strings.sending;
    config.submitBtn.disabled = true;
    config.errMsg.classList.add('hidden');
    config.errMsg.innerHTML = `<strong class="white-text">${config.strings.errors.oops}</strong><br>`;
    if (config.firstName) config.firstName.classList.remove('invalid');
    if (config.lastName) config.lastName.classList.remove('invalid');
    if (config.title) config.title.classList.remove('invalid');
    if (config.company) config.company.classList.remove('invalid');
    if (config.companySize) config.companySize.classList.remove('invalid');
    if (config.phone) config.phone.classList.remove('invalid');
    if (config.code) config.code.classList.remove('invalid');
    config.email.classList.remove('invalid');
  },

  showConfirmation(evalForm, confirmBox) {
    evalForm.classList.add('hidden');
    confirmBox.classList.remove('hidden');
  },

  resetSubmitBtn(submitBtn, strings) {
    submitBtn.classList.add('shake');
    submitBtn.value = strings.submit;
    submitBtn.removeAttribute('disabled');

    setTimeout(() => submitBtn.classList.remove('shake'), 2000);
  },

  postData(data, config) {
    const XHR = new XMLHttpRequest();

    XHR.addEventListener('load', (event) => {
      const response = JSON.parse(event.target.response);
      const uuid = (config.product === 'nessus' || config.product === 'expert') ? response.uuid : response.containerUuid;

      if (event.target.status < 200 || event.target.status >= 300) {
        const isEmailError = response.message === 'Please use a work email address';
        const serverMessage = (isEmailError ? config.strings.errors.emailWorkDomain : response.message);
        const msg = `Error: ${event.target.status}. ${serverMessage}`;

        if (isEmailError && config.isFourStepForm) this.showActiveStep(config, 2);
        if (isEmailError && config.isTwoStepForm) this.showActiveStep(config, 1);

        this.showErr(config.errMsg, msg);
        this.resetSubmitBtn(config.submitBtn, config.strings);
        this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Server Fail', msg);
        return;
      }

      if (config.product === 'essentials') {
        window.location.href = '/products/nessus/nessus-plugins/thank-you-for-registering';
      }

      this.showConfirmation(config.evalForm, config.confirmBox);
      this.fireConversionTracking(config, uuid);
    });

    XHR.addEventListener('error', (event) => {
      console.error(event);
      this.showErr(config.errMsg, `${config.strings.errors.serverFail} <a href="mailto:sales@tenable.com">sales@tenable.com</a>.`);
      this.resetSubmitBtn(config.submitBtn, config.strings);
      this.fireGTMEvent(`${this.getProductName(config.product)} Eval`, 'Server Fail', 'Network or CORS error');
    });

    XHR.open('POST', config.postUrl());
    XHR.setRequestHeader('Content-Type', 'application/json');
    XHR.send(JSON.stringify(data));
  },

  validateForm(formData, config) {
    // eslint-disable-next-line no-bitwise
    if (this.checkFieldValue(formData.first_name, config.firstName, config.strings.firstName, config)
      & this.checkFieldValue(formData.last_name, config.lastName, config.strings.lastName, config)
      & this.checkEmailDomain(formData.email, config.email, config)
      & this.checkFieldValue(formData.phone, config.phone, config.strings.phone, config)
      & this.checkFieldValue(formData.code, config.code, config.strings.code, config)
      & this.checkFieldValue(formData.title, config.title, config.strings.title, config)
      & this.checkFieldValue(formData.company, config.company, config.strings.company, config)
      & this.checkSelectValue(formData.companySize, config.companySize, config.strings.companySize, config)
      & config.checkAccept.value.length < 1) {
      this.postData(formData, config);
    } else {
      config.evalForm.querySelector('.form-item .invalid').focus();
      this.resetSubmitBtn(config.submitBtn, config.strings);
    }
  },

  setProductInterest(value) {
    const products = {
      vm: 'Tenable Vulnerability Management',
      consec: 'Tenable Vulnerability Management', // VM cuz no more CONSEC
      was: 'Tenable Web App Scanning',
      nessus: 'Tenable Nessus Professional',
      tio: 'Tenable Vulnerability Management',
      lumin: 'Tenable Vulnerability Management', // No more Lumin standalone
      cns: 'Tenable Cloud Security',
      essentials: 'Tenable Nessus Essentials',
      expert: 'Tenable Nessus Expert',
    };

    return products[value] || '';
  },

  initMultiStepForm(config) {
    const { evalForm, isFourStepForm, isTwoStepForm } = config;

    if (!isFourStepForm && !isTwoStepForm) return {};

    const multiStepConfig = {
      stepsWrapper: evalForm.querySelector('.wrap-steps'),
      formStep1: evalForm.querySelector('.form-step-1'),
      formStep2: evalForm.querySelector('.form-step-2'),
      formStep3: evalForm.querySelector('.form-step-3'),
      formStep4: evalForm.querySelector('.form-step-4'),
      goToNextStep: evalForm.querySelectorAll('.go-to-next-step'),
      goToPreviousSteps: evalForm.querySelectorAll('.go-to-previous-step'),
      goToStep2: evalForm.querySelector('.go-to-step-2'),
      goToStep3: evalForm.querySelector('.go-to-step-3'),
      goToStep4: evalForm.querySelector('.go-to-step-4'),
      filledFirstName: evalForm.querySelector('.filled-first-name'),
    };

    // Next Step Button Listeners
    if (multiStepConfig.formStep1) multiStepConfig.formStep1.addEventListener('keyup', (e) => this.goToStep2(e, config));
    if (multiStepConfig.goToStep2) multiStepConfig.goToStep2.addEventListener('click', (e) => this.goToStep2(e, config));

    if (multiStepConfig.formStep2) multiStepConfig.formStep2.addEventListener('keyup', (e) => this.goToStep3(e, config));
    if (multiStepConfig.goToStep3) multiStepConfig.goToStep3.addEventListener('click', (e) => this.goToStep3(e, config));

    if (multiStepConfig.formStep3) multiStepConfig.formStep3.addEventListener('keyup', (e) => this.goToStep4(e, config));
    if (multiStepConfig.goToStep4) multiStepConfig.goToStep4.addEventListener('click', (e) => this.goToStep4(e, config));

    // Back Button Listeners
    if (multiStepConfig.goToPreviousSteps.length > 0) {
      multiStepConfig.goToPreviousSteps
        .forEach((previousBtn) => previousBtn.addEventListener('click', (e) => this.previousStep(e, config)));
    }

    return multiStepConfig;
  },

  showActiveStep(config, activeStep) {
    const { steps, stepsWrapper } = config.multiStepConfig;

    steps.forEach((step) => {
      const formStep = stepsWrapper.querySelector(`.form-step-${step.step}`);
      const { step: stepNumber } = formStep.dataset;

      if (stepNumber === `${activeStep}`) formStep.classList.remove('hidden');
      if (stepNumber !== `${activeStep}`) formStep.classList.add('hidden');
    });

    stepsWrapper.classList.add(`step-${activeStep}`);
    stepsWrapper.classList.add(`step${activeStep}`);
    stepsWrapper.classList.remove(`step-${this.getCurrentStep(config)}`);
    stepsWrapper.classList.remove(`step${this.getCurrentStep(config)}`);

    stepsWrapper.setAttribute('data-current-step', `${activeStep}`);
  },

  getCurrentStep({ multiStepConfig }) {
    const { currentStep } = multiStepConfig.stepsWrapper.dataset;
    return currentStep ? parseInt(currentStep, 10) : 1;
  },

  goToNextStep(config) {
    const currentStep = this.getCurrentStep(config);
    const nextStep = currentStep + 1;
    this.showActiveStep(config, nextStep);
  },

  goToPreviousStep(config) {
    const currentStep = this.getCurrentStep(config);
    const previousStep = currentStep - 1;
    this.showActiveStep(config, previousStep);
  },

  clearFormErrors(config) {
    config.errMsg.classList.add('hidden');
    config.errMsg.innerHTML = `<strong class="white-text">${config.strings.errors.oops}</strong><br>`;

    config.formItemAll.forEach((formItem) => {
      formItem.classList.remove('invalid');
      if (formItem.querySelector('.eval-error') != null) {
        formItem.querySelector('.eval-error').innerText = '';
      }
      if (formItem.querySelector('.invalid') != null) {
        formItem.querySelector('.invalid').classList.remove('invalid');
      }
    });
  },

  validateFormStep(validations, config) {
    const currentStep = this.getCurrentStep(config);
    const nextStepButton = config.multiStepConfig.goToNextStep[currentStep - 1];
    nextStepButton.disabled = true;

    const isValid = validations.reduce(
      (validationStatus, validFormField) => {
        if (!validFormField) validationStatus = false;

        return validationStatus;
      },
      true,
    );

    if (isValid) {
      nextStepButton.removeAttribute('disabled');
      return true;
    }

    nextStepButton.classList.add('shake');
    nextStepButton.removeAttribute('disabled');
    setTimeout(() => nextStepButton.classList.remove('shake'), 2000);

    return false;
  },

  goToStep2(e, config) {
    e.preventDefault();
    if (e.type === 'keyup' && e.keyCode !== 13) return;

    this.clearFormErrors(config);

    const formData = {
      first_name: this.escapeHTML(config.firstName.value),
      last_name: this.escapeHTML(config.lastName.value),
      ...(config.isTwoStepForm ? {
        email: this.escapeHTML(config.email.value),
        company: this.escapeHTML(config.company.value),
      } : {}),
    };

    if (config.isTwoStepForm && config.multiStepConfig?.filledFirstName) {
      config.multiStepConfig.filledFirstName.innerText = config.firstName.value;
    }

    const isValid = this.validateFormStep([
      this.checkFieldValue(formData.first_name, config.firstName, config.strings.firstName, config),
      this.checkFieldValue(formData.last_name, config.lastName, config.strings.lastName, config),
      ...(config.isTwoStepForm ? [
        this.checkEmailDomain(formData.email, config.email, config),
        this.checkFieldValue(formData.company, config.company, config.strings.company, config),
      ] : []),
    ], config);

    if (isValid) this.goToNextStep(config);
    if (!isValid) config.multiStepConfig.formStep1.querySelector('.form-item .invalid').focus();
  },

  goToStep3(e, config) {
    e.preventDefault();
    if (e.type === 'keyup' && e.keyCode !== 13) return;

    this.clearFormErrors(config);

    const formData = {
      email: this.escapeHTML(config.email.value),
      phone: this.escapeHTML(config.phone.value),
    };

    const isValid = this.validateFormStep([
      this.checkEmailDomain(formData.email, config.email, config),
      this.checkFieldValue(formData.phone, config.phone, config.strings.phone, config),
    ], config);

    if (isValid) this.goToNextStep(config);
    if (!isValid) config.multiStepConfig.formStep2.querySelector('.form-item .invalid').focus();
  },

  goToStep4(e, config) {
    e.preventDefault();
    if (e.type === 'keyup' && e.keyCode !== 13) return;

    this.clearFormErrors(config);

    const formData = {
      title: this.escapeHTML(config.title.value),
      company: this.escapeHTML(config.company.value),
    };

    const isValid = this.validateFormStep([
      this.checkFieldValue(formData.title, config.title, config.strings.title, config),
      this.checkFieldValue(formData.company, config.company, config.strings.company, config),
    ], config);

    if (isValid) this.goToNextStep(config);
    if (!isValid) config.multiStepConfig.formStep3.querySelector('.form-item .invalid').focus();
  },

  previousStep(e, config) {
    e.preventDefault();
    this.clearFormErrors(config);
    this.goToPreviousStep(config);
  },

  submitForm(e, config) {
    e.preventDefault();

    this.showSendingState(config);

    if (config.isChooseTioApps) {
      const appsValue = [];
      config.tioAppsCheckboxes.forEach((app) => {
        if (app.checked) {
          appsValue.push(app.value);
        }
      });

      const userSelectedApps = appsValue.filter((app) => tenableEval.tioApps.indexOf(app) > -1);

      if (userSelectedApps.length > 0) {
        config.appsArr = userSelectedApps;
      } else {
        config.appsArr = tenableEval.tioApps;
      }
    }

    /* eslint-disable no-undef */
    const gclid = tnsTools.getParameterByName('gclid') || '';
    const queryParameters = `utm_promoter=${this.escapeHTML(utm_promoter)}&utm_source=${this.escapeHTML(utm_source)}&utm_medium=${this.escapeHTML(utm_medium)}&utm_campaign=${this.escapeHTML(utm_campaign)}&utm_content=${this.escapeHTML(utm_content)}&utm_term=${this.escapeHTML(utm_term)}&pid=${this.escapeHTML(pid)}&lookbook=${this.escapeHTML(lookbook)}&product_eval=${config.product}&gclid=${gclid}`;

    const formData = {
      first_name: this.escapeHTML(config.firstName && config.firstName.value),
      last_name: this.escapeHTML(config.lastName && config.lastName.value),
      email: this.escapeHTML(config.email.value),
      phone: this.escapeHTML(config.phone && config.phone.value),
      code: this.escapeHTML(config.code && config.code.value),
      country: this.escapeHTML(tns.country) || tnsTools.getCookie('tns_country') || 'US',
      region: this.escapeHTML(tns.region) || '',
      zip: this.escapeHTML(tns.zip) || '',
      title: this.escapeHTML(config.title && config.title.value),
      company: this.escapeHTML(config.company && config.company.value),
      consentOptIn: true,
      essentialsOptIn: config.essentialsOptIn ? config.essentialsOptIn.checked : false,
      pid: this.escapeHTML(pid),
      utm_source: this.escapeHTML(utm_source),
      utm_campaign: this.escapeHTML(utm_campaign),
      utm_medium: this.escapeHTML(utm_medium),
      utm_content: this.escapeHTML(utm_content),
      utm_promoter: this.escapeHTML(utm_promoter),
      utm_term: this.escapeHTML(utm_term),
      alert_email: this.escapeHTML(alert_email),
      _mkto_trk: tnsTools.getCookie('_mkto_trk') || '',
      mkt_tok: tnsTools.getParameterByName('mkt_tok') || '',
      queryParameters,
      referrer: `${window.location.href}?${queryParameters}`,
      lookbook: this.escapeHTML(lookbook),
      length_in_days: config.lengthInDays,
      apps: config.appsArr,
      companySize: (config.companySize && config.companySize.value) || '',
      preferredSiteId: (config.site && config.site.value) || '',
      tempProductInterest: this.setProductInterest(config.product) || '',
      partnerId: this.escapeHTML(config.partnerId) || '',
      gclid,
    };
    /* eslint-enable no-undef */

    console.log(formData);

    this.validateForm(formData, config);
  },

  preferredLanguageByHostname() {
    const domain = window.location.hostname;
    const domains = [
      { lang: 'de', host: 'de.tenable.com' },
      { lang: 'de', host: 'de-de-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'de', host: 'de-de-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'fr', host: 'fr.tenable.com' },
      { lang: 'fr', host: 'fr-fr-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'fr', host: 'fr-fr-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'es-la', host: 'es-la.tenable.com' },
      { lang: 'es-la', host: 'es-mx-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'es-la', host: 'es-mx-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'zh-cn', host: 'zh-cn.tenable.com' },
      { lang: 'zh-cn', host: 'www.tenablecloud.cn' },
      { lang: 'zh-cn', host: 'zh-cn-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'zh-cn', host: 'zh-cn-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'zh-tw', host: 'zh-tw.tenable.com' },
      { lang: 'zh-tw', host: 'zh-tw-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'zh-tw', host: 'zh-tw-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'jp', host: 'jp.tenable.com' },
      { lang: 'jp', host: 'ja-jp-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'jp', host: 'ja-jp-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'pt-br', host: 'pt-br.tenable.com' },
      { lang: 'pt-br', host: 'pt-br-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'pt-br', host: 'pt-br-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'it', host: 'it.tenable.com' },
      { lang: 'it', host: 'it-it-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'it', host: 'it-it-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'kr', host: 'kr.tenable.com' },
      { lang: 'kr', host: 'ko-kr-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'kr', host: 'ko-kr-wr4e7rhe-c.app.sync.sajan.com' },
      { lang: 'ar', host: 'ar.tenable.com' },
      { lang: 'ar', host: 'ar-sa-wr4e7rhe-p.app.sync.sajan.com' },
      { lang: 'ar', host: 'ar-sa-wr4e7rhe-c.app.sync.sajan.com' },
    ];

    const domainHosts = domains.map((d) => d.host);
    const domainHostIndex = domainHosts.indexOf(domain);

    if (domainHostIndex > -1) {
      return domains[domainHostIndex].lang;
    }

    return 'en';
  },

  renderForm(form) {
    const { id } = form;
    const {
      product,
      hideLabels,
      language,
      fourSteps,
      twoSteps,
      fieldError,
      floatingLabel,
      partnerId,
      edu,
      appsParam,
      hideHeadings,
    } = form.dataset;

    const findDupes = document.querySelectorAll(`#${id}`);
    const formIsDuped = findDupes.length > 1;

    if (formIsDuped) {
      form.innerHTML = `Error: #${id} is duplicated on the page`;
      return;
    }

    const formLanguage = language || this.preferredLanguageByHostname();
    const showFieldError = fieldError === 'true';
    const showFloatingLabel = floatingLabel === 'true';
    const isEdu = product === 'essentials' && edu === 'true';
    const isTwoStepForm = twoSteps === 'true';
    const isFourStepForm = fourSteps === 'true';
    const isChooseTioApps = appsParam === 'true' && product === 'tio';
    const hideStepHeadings = hideHeadings === 'true';
    let multiStepConfig = { steps: [] };
    if (isTwoStepForm) multiStepConfig = { steps: tenableEval.strings[formLanguage].steps.twoStep };
    if (isFourStepForm) multiStepConfig = { steps: tenableEval.strings[formLanguage].steps.fourStep };

    if (product === 'nessus-extension' || product === 'nessus-extension-extended' || product === 'nessus-pro-to-expert') {
      this.renderExtensionForm(form);
      return;
    }

    if (['vm', 'was', 'nessus', 'expert', 'tio', 'lumin', 'essentials', 'cns'].indexOf(product) < 0) {
      const msg = `Error: #${id} has an invalid data-product value: ${product}`;
      console.error(msg);
      form.innerHTML = msg;
      return;
    }

    if (['en', 'it', 'fr', 'de', 'es-la', 'zh-cn', 'zh-tw', 'jp', 'pt-br', 'kr', 'ar'].indexOf(formLanguage) < 0) {
      const msg = `Error: #${id} has an invalid data-language value: ${formLanguage}`;
      console.error(msg);
      form.innerHTML = msg;
      return;
    }

    form.innerHTML = this.generateFormHTML(
      id,
      product,
      hideLabels,
      tenableEval.strings[formLanguage],
      showFloatingLabel,
      isEdu,
      multiStepConfig,
      isFourStepForm,
      isTwoStepForm,
      isChooseTioApps,
      hideStepHeadings,
    );

    const evalForm = document.querySelector(`#${id}-eval-form`);

    const createAppsArr = () => {
      if (product === 'nessus') {
        return ['nessus'];
      }

      if (product === 'expert') {
        return ['expert'];
      }

      if (product === 'essentials') {
        return ['essentials'];
      }

      return tenableEval.tioApps;
    };

    const config = {
      isFourStepForm,
      isTwoStepForm,
      isChooseTioApps,
      language: formLanguage,
      product,
      id,
      confirmBox: document.querySelector(`#${id}-confirmation`),
      errMsg: document.querySelector(`#${id}-error-msg`),
      evalForm,
      floatingLabel: showFloatingLabel,
      fieldError: showFieldError,
      formItemAll: evalForm.querySelectorAll('.form-item'),
      submitBtn: evalForm.querySelector('[type=submit]'),
      firstName: evalForm.querySelector('[name=first_name]'),
      lastName: evalForm.querySelector('[name=last_name]'),
      email: evalForm.querySelector('[name=email]'),
      phone: evalForm.querySelector('[name=phone]'),
      title: evalForm.querySelector('[name=job-title]'),
      company: evalForm.querySelector('[name=company-name]'),
      consent: evalForm.querySelector('.consent'),
      consentOptIn: evalForm.querySelector('[name=consent-opt-in]'),
      essentialsOptIn: evalForm.querySelector('[name=essentials-opt-in]'),
      checkAccept: evalForm.querySelector('[name=check-accept]'),
      nessusPostUrl: '/evaluations/api/v1/nessus-pro',
      nessusExpertPostUrl: '/evaluations/api/v1/nessus-expert',
      tioPostUrl: '/evaluations/api/v1/tenable-io',
      essentialsPostUrl: '/evaluations/api/v1/nessus-essentials',
      postUrl() {
        const relativeHosts = ['www.tenable.com', 'staging.tenable.com', 'dev.tenable.com', 'localhost'];
        const addHost = relativeHosts.indexOf(window.location.hostname) > -1 ? '' : 'https://www.tenable.com';
        let path = this.tioPostUrl;
        if (this.product === 'nessus') path = this.nessusPostUrl;
        if (this.product === 'expert') path = this.nessusExpertPostUrl;
        if (this.product === 'essentials') path = this.essentialsPostUrl;
        return addHost + path;
      },
      tioAppsCheckboxes: evalForm.querySelectorAll('[name=tio-apps]'),
      appsArr: createAppsArr(),
      companySize: evalForm.querySelector('[name=company-size]') || '',
      site: evalForm.querySelector('[name=site]') || '',
      strings: tenableEval.strings[formLanguage],
      partnerId: partnerId || '',
    };

    config.multiStepConfig = { ...multiStepConfig, ...(this.initMultiStepForm(config)) };

    evalForm.addEventListener('submit', (e) => this.submitForm(e, config));

    if (config.floatingLabel) {
      config.firstName.addEventListener('blur', () => this.fieldBlur(config.firstName));
      config.firstName.addEventListener('focus', () => this.fieldFocus(config.firstName));

      config.lastName.addEventListener('blur', () => this.fieldBlur(config.lastName));
      config.lastName.addEventListener('focus', () => this.fieldFocus(config.lastName));

      config.email.addEventListener('blur', () => this.fieldBlur(config.email));
      config.email.addEventListener('focus', () => this.fieldFocus(config.email));

      config.phone.addEventListener('blur', () => this.fieldBlur(config.phone));
      config.phone.addEventListener('focus', () => this.fieldFocus(config.phone));

      config.title.addEventListener('blur', () => this.fieldBlur(config.title));
      config.title.addEventListener('focus', () => this.fieldFocus(config.title));

      config.company.addEventListener('blur', () => this.fieldBlur(config.company));
      config.company.addEventListener('focus', () => this.fieldFocus(config.company));

      config.companySize.addEventListener('blur', () => this.fieldBlur(config.companySize));
      config.companySize.addEventListener('focus', () => this.fieldFocus(config.companySize));

      if (config.site !== undefined && config.site !== '') {
        config.site.addEventListener('blur', () => this.fieldBlur(config.site));
        config.site.addEventListener('focus', () => this.fieldFocus(config.site));
      }
    }

    if (config.fieldError) {
      ['keyup', 'change', 'blur'].forEach((e) => config.firstName.addEventListener(e, (event) => this.validateField(event, config.firstName, config.strings.firstName, config, 'firstName')));
      ['keyup', 'change', 'blur'].forEach((e) => config.lastName.addEventListener(e, (event) => this.validateField(event, config.lastName, config.strings.lastName, config, 'lastName')));
      ['keyup', 'change', 'blur'].forEach((e) => config.email.addEventListener(e, (event) => this.validateField(event, config.email, config.strings.email, config, 'email')));
      ['keyup', 'change', 'blur'].forEach((e) => config.phone.addEventListener(e, (event) => this.validateField(event, config.phone, config.strings.phone, config, 'phone')));
      ['keyup', 'change', 'blur'].forEach((e) => config.title.addEventListener(e, (event) => this.validateField(event, config.title, config.strings.title, config, 'title')));
      ['keyup', 'change', 'blur'].forEach((e) => config.company.addEventListener(e, (event) => this.validateField(event, config.company, config.strings.company, config, 'company')));
      ['keyup', 'change', 'blur'].forEach((e) => config.companySize.addEventListener(e, (event) => this.validateField(event, config.companySize, config.strings.companySize, config, 'companySize')));
    }
  },

  renderExtensionForm(form) {
    const { id } = form;
    const {
      product, hideLabels, language, fieldError, floatingLabel,
    } = form.dataset;
    const formLanguage = language || this.preferredLanguageByHostname();
    const showFieldError = fieldError === 'true';
    const showFloatingLabel = floatingLabel === 'true';

    if (['nessus-extension', 'nessus-extension-extended', 'nessus-pro-to-expert'].indexOf(product) < 0) {
      const msg = `Error: #${id} has an invalid data-product value: ${product}`;
      console.error(msg);
      form.innerHTML = msg;
      return;
    }

    if (['en', 'it', 'fr', 'de', 'es-la', 'zh-cn', 'zh-tw', 'jp', 'pt-br', 'kr', 'ar'].indexOf(formLanguage) < 0) {
      const msg = `Error: #${id} has an invalid data-language value: ${formLanguage}`;
      console.error(msg);
      form.innerHTML = msg;
      return;
    }

    form.innerHTML = this.generateExtensionFormHTML(
      id,
      product,
      hideLabels,
      tenableEval.strings[formLanguage],
      showFloatingLabel,
    );

    const evalForm = document.querySelector(`#${id}-eval-form`);

    const config = {
      product,
      id,
      confirmBox: document.querySelector(`#${id}-confirmation`),
      errMsg: document.querySelector(`#${id}-error-msg`),
      evalForm,
      floatingLabel: showFloatingLabel,
      fieldError: showFieldError,
      formItemAll: evalForm.querySelectorAll('.form-item'),
      submitBtn: evalForm.querySelector('[type=submit]'),
      email: evalForm.querySelector('[name=email]'),
      code: evalForm.querySelector('[name=code]'),
      lengthInDays: product === 'nessus-extension-extended' ? 30 : 7,
      appsArr: [product],
      checkAccept: evalForm.querySelector('[name=check-accept]'),
      nessusPostUrl: '/evaluations/api/v1/nessus-pro/extension',
      nessusProToExpertPostUrl: '/evaluations/api/v1/nessus-expert/upgrade',
      postUrl() {
        const relativeHosts = ['www.tenable.com', 'staging.tenable.com', 'dev.tenable.com', 'localhost'];
        const addHost = relativeHosts.indexOf(window.location.hostname) > -1 ? '' : 'https://www.tenable.com';
        if (product === 'nessus-pro-to-expert') return addHost + this.nessusProToExpertPostUrl;
        return addHost + this.nessusPostUrl;
      },
      strings: tenableEval.strings[formLanguage],
    };

    evalForm.addEventListener('submit', (e) => this.submitForm(e, config));

    if (config.floatingLabel) {
      config.email.addEventListener('blur', () => this.fieldBlur(config.email));
      config.email.addEventListener('focus', () => this.fieldFocus(config.email));

      config.code.addEventListener('blur', () => this.fieldBlur(config.code));
      config.code.addEventListener('focus', () => this.fieldFocus(config.code));
    }

    if (config.fieldError) {
      ['keyup', 'change', 'blur'].forEach((e) => config.email.addEventListener(e, (event) => this.validateField(event, config.email, config.strings.email, config, 'email')));
      ['keyup', 'change', 'blur'].forEach((e) => config.code.addEventListener(e, (event) => this.validateField(event, config.code, config.strings.code, config, 'code')));
    }
  },

  removeFieldError(fieldVar) {
    fieldVar.classList.remove('invalid');
    fieldVar.parentElement.classList.remove('invalid');
    fieldVar.parentElement.querySelector('.eval-error').innerText = '';
  },

  fieldFocus(fieldVar) {
    fieldVar.parentElement.classList.add('focused');
    fieldVar.parentElement.classList.add('typing');
  },

  fieldBlur(fieldVar) {
    if (fieldVar.tagName === 'SELECT') {
      if (fieldVar.options[fieldVar.selectedIndex].text.trim() === '') {
        fieldVar.parentElement.classList.remove('focused');
        fieldVar.parentElement.classList.remove('typing');
      } else {
        fieldVar.parentElement.classList.add('focused');
        fieldVar.parentElement.classList.remove('typing');
      }
    } else if (fieldVar.value < 1) {
      fieldVar.parentElement.classList.remove('focused');
      fieldVar.parentElement.classList.remove('typing');
    } else {
      fieldVar.parentElement.classList.add('focused');
      fieldVar.parentElement.classList.remove('typing');
    }
  },

  validateField(event, fieldVar, fieldString, config, fieldName) {
    if (event.type === 'keyup' && event.keyCode === 9) {
      return;
    }

    const fieldVal = this.escapeHTML(fieldVar.value);

    if (fieldName === 'email') {
      const isValid = this.checkEmailDomain(fieldVal, fieldVar, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    } else if (fieldName === 'companySize') {
      const isValid = this.checkSelectValue(fieldVal, fieldVar, fieldString, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    } else {
      const isValid = this.checkFieldValue(fieldVal, fieldVar, fieldString, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    }
  },

  init() {
    const evalFormDivs = document.querySelectorAll('[id^=t-eval-]');

    if (evalFormDivs.length > 0) {
      // eslint-disable-next-line no-undef
      tnsTools.jsonp('https://cloud.tenable.com/provisioning/v1/evaluation/invalid-domains', (domainData) => {
        this.bannedDomains = domainData;
      });

      evalFormDivs.forEach((form) => this.renderForm(form));
    }
  },
};

tenableEval.init();
